<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Reporte: Entrega y Nuevos créditos" cardTextFooter>
      <template v-slot:buttons>
        <v-col cols="2">
          <v-select v-model="forms.filters.tipo" :items="catalogues.tipos" item-text="text" item-value="value"
          label="Tipo de vista" class="mx-2" :disabled="loading" required @change="setType()"></v-select>
        </v-col>
        <Basic-Btn text="Filtros" color="primary" icon="mdi-filter" @click="openFilters()" :disabled="loading || !forms.filters.tipo" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :disabled="!forms.filters.tipo" :loading="loading" />
      </template>
      <template v-slot:search>
        <Basic-Btn text="Descargar" color="primary" icon="mdi-download" @click="download()" :loading="loading"  />
      </template>
      <v-data-table :headers="headers" :items="items" :loading="loading" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
      :items-per-page="20" item-key="id" class="elevation-1" dense>
        <template v-slot:[`item.monto_entrega`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.monto_entrega) }}
        </template>
        <template v-slot:[`item.monto_pagado_pendiente`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.monto_pagado_pendiente) }}
        </template>
        <template v-slot:[`item.monto_pagado_autorizado`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.monto_pagado_autorizado) }}
        </template>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <v-dialog v-model="dialogs.filters" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Establecer filtros</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :disabled="loading" @click="clear()">
            Reiniciar
            <v-icon right>mdi-minus</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="refresh()">
            Aceptar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formFilters" lazy-va2lidation>
              <v-row class="mt-2">
                <v-col cols="6">
                  <Date-Picker v-model="forms.filters.fecha_inicio" label="Fecha inicio" :disabled="loading" />
                </v-col>
                <v-col cols="6">
                  <Date-Picker v-model="forms.filters.fecha_fin" label="Fecha fin" :disabled="loading" />
                </v-col>
                <v-col cols="12">
                  <v-autocomplete v-model="forms.filters.usuarios" label="Usuarios" :items="catalogues.usuarios" item-text="nombre_completo" item-value="idusuario" prepend-icon="mdi-account" :disabled="loading" multiple small-chips outlined dense required>
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index <= 1" small>
                        <span>{{ item.email }}</span>
                      </v-chip>
                      <span v-if="index == 2" class="grey--text text-caption">
                        (+{{ forms.filters.usuarios.length - 2 }} más)
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn, DatePicker } from "@/components"
import services from "@/utils/services"
import moment from 'moment-timezone'
import downloadjs from 'downloadjs'
import * as Papa from 'papaparse'

export default {
  name: "delivery",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DatePicker,
  },
  data: () => ({
    alert: {
      active: false,
      color: 'error',
      msg: '',
    },
    loading: false,
    catalogues: {
      tipos: [
        { text: 'Cliente', value: 'cliente' },
        { text: 'Grupo', value: 'grupo' },
      ],
      usuarios: [],
    },
    headers: [],
    headersClient: [
      { text: "GRUPO", align: "left", sortable: true, value: "grupo" },
      { text: "ID SOLICITUD", align: "left", sortable: true, value: "id_solicitud" },
      { text: "NOMBRE DEL CLIENTE", align: "left", sortable: true, value: "nombre_cliente" },
      { text: "ENCARGADA", align: "left", sortable: true, value: "encargada" },
      { text: "SUPERVISORA", align: "left", sortable: true, value: "supervisora" },
      { text: "COORDINADORA", align: "left", sortable: true, value: "coordinadora" },
      { text: "ESTATUS", align: "left", sortable: true, value: "estatus" },
      { text: "FECHA ENTREGA CLIENTE", align: "left", sortable: true, value: "fecha_entrega_cliente" },
      { text: "FECHA ACTIVO", align: "left", sortable: true, value: "fecha_activo" },
      { text: "SEMANA", align: "left", sortable: true, value: "semana" },
      { text: "MONTO ENTREGA", align: "left", sortable: true, value: "monto_entrega" },
      { text: "MONTO PAGADO PENDIENTE", align: "left", sortable: true, value: "monto_pagado_pendiente" },
      { text: "MONTO PAGADO AUTORIZADO", align: "left", sortable: true, value: "monto_pagado_autorizado" },
      { text: "MONTO DEBE ENTREGAR", align: "left", sortable: true, value: "monto_debe_entregar" },
    ],
    headersGroup: [
      { text: "GRUPO", align: "left", sortable: true, value: "grupo" },
      { text: "ENCARGADA", align: "left", sortable: true, value: "encargada" },
      { text: "SUPERVISORA", align: "left", sortable: true, value: "supervisora" },
      { text: "COORDINADORA", align: "left", sortable: true, value: "coordinadora" },
      { text: "MONTO ENTREGA", align: "left", sortable: true, value: "monto_entrega" },
      { text: "MONTO PAGADO PENDIENTE", align: "left", sortable: true, value: "monto_pagado_pendiente" },
      { text: "MONTO PAGADO AUTORIZADO", align: "left", sortable: true, value: "monto_pagado_autorizado" },
      { text: "MONTO DEBE ENTREGAR", align: "left", sortable: true, value: "monto_debe_entregar" },
    ],
    items: [],
    forms: {
      filters: {
        tipo: null,
        fecha_inicio: null,
        fecha_fin: null,
        usuarios: [],
      }
    },
    dialogs: {
      filters: false
    }
  }),
  mounted() {
    this.getCatalogues()
  },
  methods: {
    async getCatalogues() {
      const user = await this.axios.get(services.routes.user)
      if (user.data.data) {
        this.catalogues.usuarios = user.data.data
        for (let usuario of this.catalogues.usuarios) {
          const nombre = _.get(usuario, 'detalles_usuarios[0].nombre', '').trim();
          const apellido_paterno = _.get(usuario, 'detalles_usuarios[0].apellido_paterno', '').trim();
          const apellido_materno = _.get(usuario, 'detalles_usuarios[0].apellido_materno', '').trim();
          const nombre_completo = `${nombre} ${apellido_paterno} ${apellido_materno}`.trim().toUpperCase() + ` (${usuario.email})`;
          usuario.nombre_completo = nombre_completo
        }
      }
    },
    setType() {
      this.cancel()
      if (this.forms.filters.tipo === 'cliente') {
        this.headers = this.headersClient
      }
      else if (this.forms.filters.tipo === 'grupo') {
        this.headers = this.headersGroup
      }
      this.refresh()
    },
    refresh() {
      if (this.forms.filters.tipo) {
        this.loading = true
        this.items = []
        let params = {
          tipo: this.forms.filters.tipo,
          fecha_inicio: this.forms.filters.fecha_inicio,
          fecha_fin: this.forms.filters.fecha_fin,
          usuarios: this.forms.filters.usuarios,
        }
        this.axios.get(services.routes.deliveryReport, { params })
          .then((response) => {
            this.items = response.data.data
            this.cancel()
          })
          .catch((error) => {
            this.alert.active = true
            this.alert.color = 'error'
            this.alert.msg = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.alert.active = true
        this.alert.color = 'error'
        this.alert.msg = 'Debe seleccionar un tipo de vista disponible'
      }
    },
    clear() {
      this.forms.filters.fecha_inicio = null
      this.forms.filters.fecha_fin = null
      this.forms.filters.usuarios = []
    },
    cancel() {
      this.dialogs.filters = false
    },
    openFilters() {
      this.cancel()
      this.dialogs.filters = true
    },
    download() {
      if (this.items && this.items.length) {
        let data = []
        for (let i = 0; i < this.items.length; i++) {
          let row = {}
          for (let j = 0; j < this.headers.length; j++) {
            row[this.headers[j].text] = this.items[i][this.headers[j].value]
          }
          data.push(row)
        }
        const date = moment.tz('America/Monterrey').format('YYYYMMDD')
        const csv = Papa.unparse(data);
        downloadjs(csv, 'Reporte_Entrega_' + date + '.csv', 'text/csv')
      }
      else {
        this.alert.active = true
        this.alert.color = 'error'
        this.alert.msg = 'No existe información disponible'
      }
    },
    formatNumberToMoney(num) {
      try {
        const aux = Number(num);
        return aux
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } catch (e) {
        return ''
      }
    },
  },
};
</script>

<style scoped>
</style>
